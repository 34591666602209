import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Avatar,
  Heading,
  Text,
  Stars,
  Flex,
  TextLink,
} from '@feast-it/pesto';

import { initializeSurname } from '../../modules/formatting';
import Link from '../Link';

import * as Styled from './styles';

const TestimonialQuote = ({
  quote,
  avatar,
  author,
  details,
  rating,
  eventType,
  supplier,
  review,
  href,
  ...props
}) => {
  return (
    <Styled.TestimonialQuote {...props}>
      <Styled.Content>
        <Heading as="p" color="blueDark" variant="headingSmall">
          {quote}
        </Heading>

        {!!rating && <Stars rating={rating} width={170} color="gold" mt={3} />}

        <Flex alignItems="center" mt={6}>
          <Box flexShrink="0">
            <Avatar src={avatar} size={48} mr={4} />
          </Box>

          <Box>
            {!review && (
              <>
                <Text as="cite" color="blueDark" fontWeight="bold">
                  {author}
                </Text>

                <Text color="greyDark" variant="small">
                  {details}
                </Text>
              </>
            )}

            {review && (
              <>
                <Flex>
                  <Text as="cite" variant="small" color="greyDark" mr={1}>
                    {initializeSurname(author)}
                  </Text>

                  <Text variant="small" color="greyDark" mr={1}>
                    &bull;
                  </Text>

                  <Text variant="small" color="greyDark">
                    {eventType}
                  </Text>
                </Flex>

                <TextLink href={href} color="primary" wrap linkComponent={Link}>
                  <Heading variant="small" as="cite" mt={1}>
                    {supplier}
                  </Heading>
                </TextLink>
              </>
            )}
          </Box>
        </Flex>
      </Styled.Content>
    </Styled.TestimonialQuote>
  );
};

TestimonialQuote.propTypes = {
  quote: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  details: PropTypes.string,
  eventType: PropTypes.string,
  supplier: PropTypes.string,
  href: PropTypes.string,
  review: PropTypes.bool,
  rating: PropTypes.number,
};

export default TestimonialQuote;
