import React from 'react';
import {
  Flex,
  Box,
  Grid,
  Heading,
  Container,
  columnWidth,
  Carousel,
  base,
} from '@feast-it/pesto';

import TestimonialQuote from '../../components/TestimonialQuote';
import { useBreakpoint } from '../../hooks/media';

const Testimonials = rawData => {
  const data = {
    title: rawData?.primary.testimonial_title,
    items: rawData?.items.map(item => ({
      avatar: item?.testimonial_image.url,
      quote: item?.testimonial_text,
      author: item?.testimonial_name,
      details: item?.testimonial_details,
      rating: item?.testimonial_rating,
    })),
  };

  const isCarousel = data.items.length >= 3;
  const isDesktop = useBreakpoint(base.breakpoints.l);

  return (
    <Box
      as="section"
      py={{ _: 8, m: 11 }}
      px={
        isCarousel
          ? { l: 'gutter.m' }
          : { _: 'gutter._', s: 'gutter.s', m: 'gutter.m' }
      }
      bg="backDrop"
    >
      <Container
        maxWidth={!isCarousel && { _: columnWidth(6), l: columnWidth(9) }}
      >
        <Heading
          as="h2"
          variant={{ _: 'headingExtraLargeMobile', m: 'headingExtraLarge' }}
          color="blueDark"
          textAlign="center"
        >
          {data.title}
        </Heading>

        {(isDesktop || !isCarousel) && (
          <Grid
            gridTemplateColumns={{ l: `repeat(${data.items.length}, 1fr)` }}
            gridColumnGap={{ _: 'gutter._', m: 'gutter.m' }}
            gridRowGap={9}
            mt={10}
          >
            {data.items.map((item, i) => (
              <Flex alignItems="center" key={`testimonial-grid-${i}`}>
                <TestimonialQuote {...item} />
              </Flex>
            ))}
          </Grid>
        )}

        {!isDesktop && isCarousel && (
          <Carousel arrows={false} dotOptions={{ py: 0 }}>
            {data.items.map((item, i) => (
              <Flex
                justifyContent="center"
                alignItems="center"
                key={`testimonial-carousel-${i}`}
                px={{ _: 'gutter._', s: 'gutter.s', m: 'gutter.m' }}
                py={6}
              >
                <TestimonialQuote maxWidth={columnWidth(6)} {...item} />
              </Flex>
            ))}
          </Carousel>
        )}
      </Container>
    </Box>
  );
};

export default Testimonials;
