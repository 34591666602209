import styled from '@emotion/styled/macro';
import { space, layout } from 'styled-system';
import { rem, minWidth } from '@feast-it/pesto';

export const TestimonialQuote = styled.div`
  position: relative;
  margin-top: ${rem(10)};
  padding: ${({ theme }) => theme.space[7]} ${({ theme }) => theme.space[5]};
  border-radius: ${({ theme }) => theme.radii[2]};
  background: ${({ theme }) => theme.colors.white};
  width: 100%;
  ${space}
  ${layout}

  ${({ theme }) => minWidth(theme.breakpoints.l)} {
    margin-top: 0;
    padding-left: ${({ theme }) => theme.space[9]};
  }

  &::before {
    content: '“';
    position: absolute;
    top: ${rem(-22)};
    left: 50%;
    font-family: ${({ theme }) => theme.fonts.heading};
    font-size: ${rem(100)};
    color: ${({ theme }) => theme.colors.primary};
    transform: translateX(-50%);

    ${({ theme }) => minWidth(theme.breakpoints.l)} {
      top: ${({ theme }) => theme.space[6]};
      left: 0;
    }
  }
`;

export const Content = styled.blockquote`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
